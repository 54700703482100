/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* @font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Poppins Regular"),
    url("./fonts/poppins-web/Poppins-Regular.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Poppins Italic"),
    url("./fonts/poppins-web/Poppins-Italic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Poppins Thin"),
    url("./fonts/poppins-web/Poppins-Thin.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local("Poppins Thin Italic"),
    url("./fonts/poppins-web/Poppins-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local("Poppins ExtraLight"),
    url("./fonts/poppins-web/Poppins-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local("Poppins ExtraLight Italic"),
    url("./fonts/poppins-web/Poppins-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Poppins Light"),
    url("./fonts/poppins-web/Poppins-Light.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Poppins Light Italic"),
    url("./fonts/poppins-web/Poppins-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Poppins Medium"),
    url("./fonts/poppins-web/Poppins-Medium.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local("Poppins Medium Italic"),
    url("./fonts/poppins-web/Poppins-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Poppins SemiBold"),
    url("./fonts/poppins-web/Poppins-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local("Poppins SemiBold Italic"),
    url("./fonts/poppins-web/Poppins-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Poppins Bold"),
    url("./fonts/poppins-web/Poppins-Bold.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Poppins Bold Italic"),
    url("./fonts/poppins-web/Poppins-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Poppins ExtraBold"),
    url("./fonts/poppins-web/Poppins-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local("Poppins ExtraBold Italic"),
    url("./fonts/poppins-web/Poppins-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Poppins Black"),
    url("./fonts/poppins-web/Poppins-Black.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local("Poppins Black Italic"),
    url("./fonts/poppins-web/Poppins-BlackItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Regular"),
    url("./fonts/roboto-web/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Italic"),
    url("./fonts/roboto-web/Roboto-Italic.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Roboto Thin"),
    url("./fonts/roboto-web/Roboto-Thin.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local("Roboto Thin Italic"),
    url("./fonts/roboto-web/Roboto-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"),
    url("./fonts/roboto-web/Roboto-Light.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light Italic"),
    url("./fonts/roboto-web/Roboto-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"),
    url("./fonts/roboto-web/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium Italic"),
    url("./fonts/roboto-web/Roboto-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"),
    url("./fonts/roboto-web/Roboto-Bold.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"),
    url("./fonts/roboto-web/Roboto-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black"),
    url("./fonts/roboto-web/Roboto-Black.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local("Roboto Black Italic"),
    url("./fonts/roboto-web/Roboto-BlackItalic.woff") format("woff");
} */

html {
  font-family: "Poppins", sans-serif;
  font-family: "DM Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0 !important;
  overflow-y: scroll !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  font-family: "DM Sans", sans-serif;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
  background: rgba(240, 243, 247, 1);
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: inline-block;
}